import React, { Component, Fragment } from 'react';
import GreenHeart from '../../assets/heart.png'

class DefaultFooter extends Component {

  render() {
    return (
      <Fragment>
        <div style={{width:'100%', display:'flex',paddingTop:'1.5rem',paddingBottom:'1.0rem', textAlign:"center", justifyContent:"center",alignItems:'center',backgroundColor:"black"}}>
          <p style={{ color: 'white' }}>WE <img src={GreenHeart} alt="Green Heart"></img> CODE!</p>
        </div>
      </Fragment>
    );
  }

}

export default DefaultFooter;
