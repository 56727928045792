import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return (
    <h1 className="loading">Loading...</h1>
  )
}

const Home = Loadable({
  loader: () => import('./views/Home'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  //Real Pages
  { path: '/home', name: 'Home', component: Home },

];

export default routes;
