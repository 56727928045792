import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-boost'
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/all.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages


const client = new ApolloClient({
  uri: 'https://aaa-server.herokuapp.com',
  request: async operation => {
    // const token = await localStorage.getItem('token');
    const token = process.env.REACT_APP_TOKEN;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });
  }
});

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
