import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { graphql } from 'react-apollo';

import {
  AppFooter,
} from '@coreui/react';

import me from '../../queries/me';
//sidebar nav config
// import navigation from '../../_nav';
//import navigationSA from '../../_navSA';
// routes config
import routes from '../../routes';

//import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
//import DefaultHeader from './DefaultHeader';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];
    this.state = {
      isAdminPage : false
    };
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.isAdminPage = this.isAdminPage.bind(this);

    for (let i; i < this.events.length; i += 1) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  isAdminPage(value) {
    this.setState({ isAdminPage: value })
  }

  setTimeout() {
    this.logoutTimeout = setTimeout(this.logout, 1800000);
  }

  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
    localStorage.removeItem('token');
    window.location.reload();
    this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (let i; i < this.events.length; i += 1) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {
    const { history, data } = this.props;
    //const navProp = navigation
    // console.log(this.props, "props default header")
    if (data.loading) {
      return <div />;
    }
   // console.log(localStorage.getItem('token'))
    //console.log(this.state.isAdminPage)
    if(!localStorage.getItem('token') && this.state.isAdminPage){
      history.push('/login')
    }

    // console.log("history", history.location.pathname);
    return (
      <div
        className="app"
        onLoad={() => this.resetTimeout()}
        onFocus={() => this.resetTimeout()}
        onScroll={() => this.resetTimeout()}
        onMouseMove={() => this.resetTimeout()}
        onKeyPress={() => this.resetTimeout()}
        role="presentation"
      >
        <div className="app-body">
          { /* this.state.isAdminPage ?
          <AppSidebar fixed minimized>
            <AppSidebarMinimizer />
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navProp} {...this.props} />
            <AppSidebarFooter />
          </AppSidebar>
         : null */}
          {/*  */}
          <main className="main">
            {/*<AppBreadcrumb appRoutes={routes}/>*/ }
            <Container fluid className={history.location.pathname === '/search' ? 'p-0' : ''}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component
                      {...props}
                      isAdminPage={this.isAdminPage}
                    />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/home" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default graphql(me)(DefaultLayout);
